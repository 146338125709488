<template>
  <div>
    <div class="header">
      <div class="header_top">
        当前位置：
        <span class="center">人资管理</span>
        <img src="../../assets/right.png"
             alt />
        <span class="center">待入职员工列表</span>
      </div>
    </div>
    <div class="backCard">
      <div style="display: flex; align-items: center; flex-wrap: wrap">
        <div>
          <span class="seach_text">姓名：</span>
          <el-input placeholder="请输入姓名"
                    v-model="formInline.f_like_name"
                    size="mini"
                    style="margin-right: 10px; width: 150px"
                    prefix-icon="el-icon-search"
                    clearable></el-input>
        </div>
        <div>
          <span class="seach_text">手机号：</span>
          <el-input placeholder="请输入手机号"
                    v-model="formInline.f_like_username"
                    size="mini"
                    style="margin-right: 10px; width: 150px"
                    prefix-icon="el-icon-search"
                    clearable></el-input>
        </div>
        <div>
          <span class="seach_text">身份证号：</span>
          <el-input placeholder="请输入身份证号"
                    v-model="formInline.f_like_idCardNumber"
                    size="mini"
                    style="margin-right: 10px; width: 150px"
                    prefix-icon="el-icon-search"
                    clearable></el-input>
        </div>
        <div>
          <span class="seach_text">用工企业名称：</span>
          <el-select remote
                     reserve-keyword
                     :remote-method="query"
                     @change="getCity"
                     v-model="formInline.workEnterpriseCode"
                     clearable
                     filterable
                     placeholder="请输入企业名称"
                     size="mini"
                     style="margin-right: 10px">
            <el-option v-for="item in eList"
                       :key="item.code"
                       :label="item.name"
                       :value="item.code"></el-option>
          </el-select>
        </div>
        <div>
          <span class="seach_text">企业区域：</span>
          <el-select v-model="formInline.workEnterpriseAreaCode"
                     @change="querycity"
                     style="margin-right: 10px; width: 200px"
                     filterable
                     clearable
                     size="mini"
                     :placeholder="'请输入企业区域'">
            <el-option-group v-for="group in areaSelectList"
                             :key="group.provinceName"
                             :label="group.provinceName">
              <span style="color: red"
                    slot="label">
                {{
                group.provinceName
                }}
              </span>
              <el-option v-for="item in group.citys"
                         :key="item.code"
                         :label="item.cityName"
                         :value="item.code"></el-option>
            </el-option-group>
          </el-select>
        </div>

        <div>
          <span class="seach_text">企业门店：</span>
          <el-select remote
                     reserve-keyword
                     v-model="formInline.workEnterpriseStoreCode"
                     clearable
                     filterable
                     placeholder="请输入企业门店"
                     size="mini"
                     style="margin-right: 10px">
            <el-option v-for="item in storeList"
                       :key="item.code"
                       :label="item.name"
                       :value="item.code"></el-option>
          </el-select>
        </div>
        <div>
          <span class="seach_text">入职合同：</span>
          <el-select remote
                     reserve-keyword
                     v-model="formInline.enterContractStatus"
                     clearable
                     placeholder="请选择入职合同"
                     size="mini"
                     style="margin-right: 10px">
            <el-option v-for="item in entryList"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </div>
        <div>
          <span class="seach_text">工作性质：</span>
          <el-select remote
                     reserve-keyword
                     v-model="formInline.kind"
                     clearable
                     placeholder="请选择入职合同"
                     size="mini"
                     style="margin-right: 10px">
            <el-option v-for="item in kindList"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </div>
        <div>
          <span class="seach_text">分配时间：</span>
          <el-date-picker :clearable="true"
                          @change="timeCustomer()"
                          v-model="dateTime"
                          type="daterange"
                          size="mini"
                          placeholder="选择日期"
                          format="yyyy 年 MM 月 dd 日"
                          value-format="yyyy-MM-dd"></el-date-picker>
        </div>

        <el-button size="small"
                   style="margin: 10px; border-radius: 5px"
                   type="primary"
                   @click="seach">查询</el-button>
      </div>
    </div>
    <el-table :data="list"
              style="width: 100%">
      <el-table-column label="员工姓名 "
                       width="280"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.name }}
          <span v-if="!scope.row.name">{{ scope.row.nickname }}</span>

        </template>
      </el-table-column>
      <el-table-column label="所属企业">
        <template slot-scope="scope">
          <span v-if="scope.row.enterprise">{{ scope.row.enterprise.name }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="性别"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <span style="color: blue"
                v-if="scope.row.sex === '男'">
            {{
            scope.row.sex
            }}
          </span>
          <span style="color: red"
                v-else>{{ scope.row.sex }}</span>
          <span v-if="scope.row.idCardNumber">
            -{{
            year - parseInt(scope.row.idCardNumber.slice(6, 10))
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="手机号">
        <template slot-scope="scope">
          <div v-if="scope.row.username"
               class="clickColor">
            <span v-if="scope.row.usernameShow"
                  @click="scope.row.usernameShow = false">
              {{
              scope.row.username.substr(0, 3) +
              new Array(scope.row.username.length - 7).join(
              '*'
              ) +
              scope.row.username.substr(-4)
              }}
            </span>
            <span v-else
                  @click="scope.row.usernameShow = true">
              {{
              scope.row.username
              }}
            </span>
          </div>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="身份证号">
        <template slot-scope="scope">
          <div v-if="scope.row.idCardNumber"
               class="clickColor">
            <span v-if="scope.row.idCardNumberShow"
                  @click="scope.row.idCardNumberShow = false">
              {{
              scope.row.idCardNumber.substr(0, 3) +
              new Array(
              scope.row.idCardNumber.length - 7
              ).join('*') +
              scope.row.idCardNumber.substr(-4)
              }}
            </span>
            <span v-else
                  @click="scope.row.idCardNumberShow = true">{{ scope.row.idCardNumber }}</span>
          </div>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="银行卡号">
        <template slot-scope="scope">
          <div v-if="scope.row.bankCard">{{ scope.row.bankCard.bankName }}</div>
          <div class="clickColor"
               v-if="scope.row.bankCard">
            <span v-if="scope.row.show"
                  @click="scope.row.show = false">
              {{
              scope.row.bankCard.accountCode.substr(0, 3) +
              new Array(
              scope.row.bankCard.accountCode.length - 7
              ).join('*') +
              scope.row.bankCard.accountCode.substr(-4)
              }}
            </span>
            <span v-else
                  @click="scope.row.show = true">
              {{
              scope.row.bankCard.accountCode
              }}
            </span>
          </div>
          <span v-else
                class="clickColor">--</span>
        </template>
      </el-table-column>
      <el-table-column label="用工企业"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-if="scope.row.workEnterprise">{{ scope.row.workEnterprise.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="企业区域"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <span>
            {{ scope.row.area.provinceName }}-{{
            scope.row.area.cityName
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="企业门店"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.store.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="入职合同"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <span class="isAuthenticated"
                v-if="scope.row.staff.enterContractStatus==='0'">未创建</span>
          <span class="Authenticated"
                v-if="scope.row.staff.enterContractStatus==='2'">签署完成</span>
          <span class="isAuthenticated"
                v-if="scope.row.staff.enterContractStatus==='100'">待发起</span>
          <span class="isAuthenticated"
                v-if="scope.row.staff.enterContractStatus==='101'">签署中</span>
          <span class="Authenticated"
                v-if="scope.row.staff.enterContractStatus==='103'">已完成</span>
          <span class="Authenticated"
                v-if="scope.row.staff.enterContractStatus==='104'">审核不通过</span>
          <span class="isAuthenticated"
                v-if="scope.row.staff.enterContractStatus==='105'">待企业签署</span>
          <span class="isAuthenticated"
                v-if="scope.row.staff.enterContractStatus==='3'">失败</span>
          <span class="isAuthenticated"
                v-if="scope.row.staff.enterContractStatus==='7'">拒签</span>
                <span class="isAuthenticated"
                v-if="scope.row.staff.enterContractStatus==='5'">已过期</span>
        </template>
      </el-table-column>
      <el-table-column label="工作性质"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-if="scope.row.staff.kind==='1'">全职</span>
          <span v-if="scope.row.staff.kind==='2'">灵活用工</span>
        </template>
      </el-table-column>
      <el-table-column label="分配时间"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.staff.create_time }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作"
                       fixed="right"
                       width="100px">
        <template slot-scope="scope">
          <div class="shuxian">
            <span class="clickColor"
                  @click="goDetail(scope.row.staff.code)">详情</span>
            <el-divider direction="vertical"></el-divider>
          </div>
          <div class="shuxian">
            <span class="clickColor"
                  @click="goEdit(scope.row)">审核</span>
            <el-divider direction="vertical"></el-divider>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <addDialog :dialogVisible.sync="addDialogVisible"
               :code.sync="code"
               :title.sync="title"
               @func="addClose()"></addDialog>
    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor"
            style="margin: 0 10px">每页{{ pageSize }}条</span>
      <el-pagination background
                     :current-page.sync="pageNumber"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :page-size="pageSize"
                     prev-text="上一页"
                     next-text="下一页"
                     layout="prev,pager, next,jumper"
                     :total="total"></el-pagination>
    </div>
  </div>
</template>

<script>
import { api } from '../../api'
import moment from 'moment';
export const staffList = api()('staff.list.json')

export const workEnterpriseList = api()('work_enterprise.list.json')
// 区域列表
export const workEnterpriseAreaList = api()('work_enterprise_area.list.json')
export const workEnterpriseStoreList = api()('/work_enterprise_store.list.json')
export const workEnterpriseAreaContainArea = api()(
  'work_enterprise_area.containArea.json'
)
export default {
  components: {
    addDialog: () => import('./examineDialog'),
  },
  data () {
    return {
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      code: '',
      title: '',
      formInline: {
        f_like_name: '',
        f_like_username: '',
        f_like_idCardNumber: '',
        workEnterpriseCode: '',
        workEnterpriseAreaCode: '',
        workEnterpriseStoreCode: '',
        enterContractStatus: '',
        kind: '',
        dispatchStartTime: '',
        dispatchEndTime: '',
      },
      list: [],
      eList: [],
      storeList: [],
      areaSelectList: [],
      kindList: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '全职',
          value: '1',
        },
        {
          label: '灵活用工',
          value: '2',
        },
      ],
      entryList: [
        {
          label: '全部',
          value: '',
        },

        {
          label: '未创建',
          value: '0',
        },
        {
          label: '签署完成',
          value: '2',
        },
        {
          label: '待发起',
          value: '100',
        },
        {
          label: '签署中',
          value: '101',
        },
        {
          label: '已完成',
          value: '103',
        },
        {
          label: '待企业签署',
          value: '105',
        },

      ],
      year: '',
      addDialogVisible: false,
      dateTime: [],

    }
  },
  computed: {
    classVal: function () {
      return {
        'font-color-warning': true,
      }
    },
  },
  created () {
    this.year = new Date().getFullYear()
    console.log(this.year)
    this.loadData()
    this.enterpriseList()
  },
  methods: {
    async timeCustomer () {

      if (this.dateTime != null) {
        this.formInline.dispatchStartTime = moment(this.dateTime[0])
          .startOf('day')
          .format('YYYY-MM-DD HH:mm:ss');
        this.formInline.dispatchEndTime = moment(this.dateTime[1])
          .endOf('day')
          .format('YYYY-MM-DD HH:mm:ss');
      }
      else {
        this.formInline.dispatchStartTime = ''
        this.formInline.dispatchEndTime = ''
      }


    },
    goDetail (obj) {
      this.$router.push({
        path: '/system/user/detail',
        query: { code: obj },
      })
    },
    goEdit (row) {
      this.addDialogVisible = true

      this.code = row.staff.code
      this.title = '入职审核'
    },
    addClose () {
      this.loadData()
    },
    getCity (val) {
      if (val) {
        const params = {
          workEnterpriseCode: val
        };
        workEnterpriseAreaContainArea(params).then(res => {
          this.areaSelectList = res
        });
      }
      else {
        this.areaSelectList = []
        this.storeList = []
        this.formInline.workEnterpriseStoreCode = ''
        this.formInline.workEnterpriseAreaCode = ''
      }
    },
    querycity (val) {
      if (val) {
        workEnterpriseStoreList({ workEnterpriseAreaCode: val }).then(
          (res) => {
            this.storeList = res.list
            console.log(this.storeList)
          }
        )
      }
      else {

        this.formInline.workEnterpriseStoreCode = ''
        this.storeList = []
      }

    },
    query (val) {
      this.pageNumber = 1
      this.enterpriseList(val)
    },
    keywords () {
      this.pageNumber = 1
      this.enterpriseList()
    },
    enterpriseList (val) {
      const params = {
        keywords: val,
      }
      workEnterpriseList(params).then((res) => {
        this.eList = res.list
      })
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.loadData()
    },
    handleCurrentChange (val) {
      this.pageNumber = val
      this.loadData()
    },
    async loadData () {
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        ...this.formInline,
        workState: '1'
      }
      const res = await staffList(params)
      this.list = res.list.map((it) => {
        return {
          ...it,
          bankCard: it.bankCard || { accountCode: '' },
          show: !!it.bankCard,
          usernameShow: true,
          idCardNumberShow: true,
        }
      })
      this.total = res.total
      console.log(this.list)
    },
    seach () {
      this.pageNumber = 1
      this.loadData()
    },
  },
}
</script>
<style>
.el-select-group__title {
  color: black;
  font-size: 15px;
  font-weight: 700;
  margin-left: -10px;
}
</style>
<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  border: none;
  height: 40px;
  align-items: center;
  font-weight: 400;
  color: #606266;
  cursor: pointer;

  .header_top {
    display: flex;
    align-items: center;

    img:nth-child(1) {
      width: 20px;
      height: 17px;
    }

    img:nth-child(2) {
      width: 9px;
      height: 10px;
    }

    span {
      margin: 0 10px;
      font-size: 16px;
      color: #606060;
    }
  }
}

.clickColor {
  color: #409eff;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.el-form-item {
  margin-top: 12px !important;
  margin-bottom: 10px;
}

.table_border {
  margin: 20px 0;

  .table_top {
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    background: #4977fc;
    color: #fff;
    font-size: 14px;
  }

  .table_demo {
    padding: 20px 0;
    font-size: 10px;
  }
}

.del-pop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.del-title {
  font-size: 17px;
  text-align: center;
  color: #333333;
  margin: 15px 0;
}

.del-sumtitle {
  font-size: 13px;
  text-align: center;
  color: #a9a9a9;
  margin: 0 0 5px 0;
}
.backCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
</style>
